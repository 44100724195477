// Water Smart Card Application Request List
export const smartCardNewApplicationList = 'farmer-smart-card-app/new-app-list'
export const smartCardReissueApplicationList = 'farmer-smart-card-app/reissue-app-list'
export const smartCardApprovalList = 'farmer-smart-card-app/approval-list'
export const smartCardGeneratedList = 'farmer-smart-card-app/card-generated-list'

// Status
export const smartCardApplicationProcessing = 'farmer-smart-card-app/update-as-processing'
export const smartCardApplicationApproved = 'farmer-smart-card-app/update-as-approved/'

// Card Generate
export const smartCardGenerated = 'farmer-smart-card-app/update-as-generated/'

// Review
export const smartCardApplicationReviewList = 'farmer-smart-card-app/review-list'
export const smartCardApplicationReview = 'farmer-smart-card-app/update-as-review'

// Reject
export const smartCardApplicationRejectList = 'farmer-smart-card-app/reject'
export const smartCardApplicationReject = 'farmer-smart-card-app/update-as-reject'

// Water Testing
export const waterTestingSingleDetails = 'farmer-water-test-application/single-details/'

// Smart Card
export const smartCardSingleDetails = 'farmer-smart-card-app/single-details/'

// Scheme Application
export const applicationShow = '/scheme-application/show'

// Pump Operator Application
export const pumpOptApplicationSingleDetails = '/farmer-pump-operator-application/single-details/'
export const getLoggedUserSupervisor = '/user/supervisor'
