<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('cardPayment.card_list') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.far_division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.far_district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.far_upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.far_union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('cardPayment.application_id')"
            label-for="application_id"
            >
            <b-form-input
              plain
              v-model="search.application_id"
              id="application_id"
              >
            </b-form-input>
            </b-form-group>
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('cardPayment.card_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span>{{ data.item.updated_at | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(view)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="Details" v-b-modal.modal-detail @click="details(data.item)"><i class="far fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(attachment)="data">
                      <b-button class="btn btn-success" v-b-modal.modal-card @click="cardView(data.item)"><i class="fa fa-id-card" aria-hidden="true"></i> {{ $t('cardPayment.card_view') }}</b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-card" size="lg" :title="$t('cardPayment.card')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <CardModal :id="editItemId" :item="item" />
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <!-- <ApplicationDetails :id="editItemId" :key="editItemId" :item="item" /> -->
      <DetailModal :id="editItemId" :key="editItemId" :item="item" />
    </b-modal>
  </b-container>
</template>
<script>
// import ApplicationDetails from '../ApplicationDetails'
import DetailModal from './DetailModal'
import CardModal from './CardModal'
import { smartCardGeneratedList } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '@/Utils/export-pdf'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    // ApplicationDetails,
    DetailModal,
    CardModal
  },
  data () {
    return {
      search: {
        org_id: '0',
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        status: '0',
        farmer_id: '',
        application_id: ''
      },
      editItemId: 0,
      item: '',
      rows: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    detailTitle () {
      return this.$t('globalTrans.details')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('globalTrans.application_id'), class: 'text-center' },
        { label: this.$t('farmerOperator.applicant_name'), class: 'text-center' },
        { label: this.$t('globalTrans.application_date'), class: 'text-center' },
        { label: this.$t('globalTrans.view_application'), class: 'text-center' },
        { label: this.$t('cardPayment.generated_card'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'created_at' },
          { key: 'view' },
          { key: 'attachment' },
          { key: 'status_name_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'created_at' },
          { key: 'view' },
          { key: 'attachment' },
          { key: 'status_name' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
          } else {
              return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
          }
      })
    }
  },
  watch: {
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    cardView (item) {
      this.editItemId = item.id
      this.item = item
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    pdfExport (data) {
      const rowData = this.getPdfData(data)
      const extraData = {
        totalHeaderRows: 0
      }
      const reportTitle = this.$i18n.locale === 'en' ? 'Smart Cart Request Report' : 'স্মার্ট কার্ড অনুরোধের প্রতিবেদন'
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', data.org_id, reportTitle, rowData, null, extraData)
    },
    getPdfData (data) {
      if (this.$i18n.locale === 'bn') {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name_bn },
            { text: this.$t('cardPayment.id_serial') }, { text: this.$n(data.id_serial) }
          ],
          [
            { text: this.$t('cardPayment.applicant_name') }, { text: data.name_bn },
            { text: this.$t('cardPayment.email') }, { text: data.email }
          ],
          [
            { text: this.$t('cardPayment.father_name') }, { text: data.father_name_bn },
            { text: this.$t('cardPayment.mother_name') }, { text: data.mother_name_bn }
          ],
          [
            { text: this.$t('cardPayment.marital_status') }, { text: data.marital_status_bn },
            { text: this.$t('cardPayment.spouse_name') }, { text: data.spouse_name_bn }
          ],
          [
            { text: this.$t('cardPayment.no_of_child') }, { text: this.$n(data.no_of_child, { useGrouping: false }) },
            { text: this.$t('cardPayment.nid') }, { text: this.$n(data.nid, { useGrouping: false }) }
          ],
          [
            { text: this.$t('cardPayment.mobile_no') }, { text: this.$n('0' + data.mobile_no, { useGrouping: false }) },
            { text: this.$t('cardPayment.gender') }, { text: data.gender_bn }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.district_name_bn },
            { text: this.$t('complain.district') }, { text: data.district_name_bn }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name_bn },
            { text: this.$t('farmerOperator.village') }, { text: data.far_village_bn }
          ],
          [
            { text: this.$t('cardPayment.date_of_birth') }, { text: this.$d(new Date(data.date_of_birth)) },
            { text: this.$t('cardPayment.qualification') }, { text: data.qualification }
          ],
          [
            { text: this.$t('cardPayment.owned_land') }, { text: data.owned_land },
            { text: this.$t('cardPayment.lease_land') }, { text: data.lease_land }
          ],
          [
            { text: this.$t('cardPayment.barga_land') }, { text: data.barga_land },
            { text: this.$t('cardPayment.total_land') }, { text: data.total_land }
          ],
          [
            { text: this.$t('cardPayment.training_info') }, { text: data.training_info },
            { text: this.$t('cardPayment.earnings') }, { text: this.$n(data.earnings) }
          ],
          [
            { text: this.$t('cardPayment.crop_plan') }, { text: data.crop_plan_bn },
            { text: this.$t('globalTrans.application_date') }, { text: this.$d(new Date(data.created_at)) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.status_name_bn },
            { text: this.$t('farmerOperator.farmer_id') }, { text: this.$n(data.farmer_id) }
          ]
        ]
        return rowData
      } else {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name },
            { text: this.$t('cardPayment.id_serial') }, { text: this.$n(data.id_serial) }
          ],
          [
            { text: this.$t('cardPayment.applicant_name') }, { text: data.name },
            { text: this.$t('cardPayment.email') }, { text: data.email }
          ],
          [
            { text: this.$t('cardPayment.father_name') }, { text: data.father_name },
            { text: this.$t('cardPayment.mother_name') }, { text: data.mother_name }
          ],
          [
            { text: this.$t('cardPayment.marital_status') }, { text: data.marital_status },
            { text: this.$t('cardPayment.spouse_name') }, { text: data.spouse_name }
          ],
          [
            { text: this.$t('cardPayment.no_of_child') }, { text: this.$n(data.no_of_child, { useGrouping: false }) },
            { text: this.$t('cardPayment.nid') }, { text: this.$n(data.nid, { useGrouping: false }) }
          ],
          [
            { text: this.$t('cardPayment.mobile_no') }, { text: this.$n('0' + data.mobile_no, { useGrouping: false }) },
            { text: this.$t('cardPayment.gender') }, { text: data.gender_en }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.district_name },
            { text: this.$t('complain.district') }, { text: data.district_name }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name },
            { text: this.$t('farmerOperator.village') }, { text: data.far_village }
          ],
          [
            { text: this.$t('cardPayment.date_of_birth') }, { text: this.$d(new Date(data.date_of_birth)) },
            { text: this.$t('cardPayment.qualification') }, { text: data.qualification }
          ],
          [
            { text: this.$t('cardPayment.owned_land') }, { text: data.owned_land },
            { text: this.$t('cardPayment.lease_land') }, { text: data.lease_land }
          ],
          [
            { text: this.$t('cardPayment.barga_land') }, { text: data.barga_land },
            { text: this.$t('cardPayment.total_land') }, { text: data.total_land }
          ],
          [
            { text: this.$t('cardPayment.training_info') }, { text: data.training_info },
            { text: this.$t('cardPayment.earnings') }, { text: this.$n(data.earnings) }
          ],
          [
            { text: this.$t('cardPayment.crop_plan') }, { text: data.crop_plan },
            { text: this.$t('globalTrans.application_date') }, { text: this.$d(new Date(data.created_at)) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.status_name },
            { text: this.$t('farmerOperator.farmer_id') }, { text: this.$n(data.farmer_id) }
          ]
        ]
        return rowData
      }
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        waterTestingRequest_name: '',
        waterTestingRequest_name_bn: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(irriSchemeServiceBaseUrl, smartCardGeneratedList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const smartCardStatusList = this.$store.state.commonObj.smartCardStatusList
      const maritalStatus = this.$store.state.commonObj.maritalStatus
      const genderList = this.$store.state.commonObj.genderList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.far_division_id)
        const districtObject = districtList.find(district => district.value === item.far_district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
        const unionObject = unionList.find(union => union.value === item.far_union_id)
        const statusListObject = smartCardStatusList.find(status => status.id === item.status)
        const maritalStatusObject = maritalStatus.find(status => status.value === item.marital_status)
        const genderStatusObject = genderList.find(status => status.value === item.gender)
        const orgData = {
          org_name: orgObject.text_en,
          org_name_bn: orgObject.text_bn
        }
        const divisionData = {
          division_name: divisionObject.text,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text,
          union_name_bn: unionObject.text_bn
        }

        const cardStatusData = {
          status_name: statusListObject.name,
          status_name_bn: statusListObject.name_bn
        }

        const maritalStatusData = {
          marital_status: maritalStatusObject.text,
          marital_status_bn: maritalStatusObject.text_bn
        }

        const genderStatusData = {
          gender_en: genderStatusObject.text,
          gender_bn: genderStatusObject.text_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, cardStatusData, maritalStatusData, genderStatusData)
      })
    },
    getDistrictList (divisionId) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    }
  }
}
</script>
